<template>
  <Layout>
    <PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
    <div class="block_content position-relative">
      <div class="modal-content">
        <div class="modal-header bg-light p-3">
          <h5 class="modal-title">{{ $t("base.user_info") }}</h5>
          <div class="d-flex align-items-center">
            <div class="text-primary me-3 cursor" @click="toggleStatus()">
              <i class="fs-18 ri-pencil-fill"></i>
            </div>
            <div class="text-primary cursor" @click="handleClose()">
              <i class="fs-24 ri-close-line"></i>
            </div>
          </div>
        </div>
        <form action="#" id="addform">
          <div class="modal-body">
            <div class="row gy-3">
              <!-- 名称 -->
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.name") }}</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.name"
                  :class="{
                    'is-invalid': submitted && v$.form.name.$error,
                  }"
                  required
                  :disabled="isAbled"
                />
                <div
                  v-for="(item, index) in v$.form.name.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <!-- 用户名 -->
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("base.user_center_name") }}</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.username"
                  :class="{
                    'is-invalid': submitted && v$.form.username.$error,
                  }"
                  required
                  disabled
                />
                <div
                  v-for="(item, index) in v$.form.username.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <!-- 密码 -->
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.password") }}</span>
                </label>
                <div class="position-relative auth-pass-inputgroup">
                  <input
                    type="password"
                    autocomplete="new-passWord"
                    class="form-control"
                    style="padding-right: 3rem"
                    v-model="form.password"
                    :class="{
                      'is-invalid': submitted && v$.form.password.$error,
                    }"
                    required
                    disabled
                  />
                  <div
                    v-for="(item, index) in v$.form.password.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                  <div class="mt-2 text-end">
                    <a
                      href="javascript:void(0);"
                      class="link-primary text-decoration-underline"
                      @click="() => (showEditPassWord = true)"
                      >{{ $t("base.change_password") }}</a
                    >
                  </div>
                </div>
              </div>
              <!-- 角色 -->
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.role") }}</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.role"
                  :class="{
                    'is-invalid': submitted && v$.form.role.$error,
                  }"
                  required
                  disabled
                />
                <div
                  v-for="(item, index) in v$.form.role.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <!-- 组织 -->
              <div>
                <label class="form-label">{{
                  $t("attribute.organization")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.department"
                  :disabled="isAbled"
                />
              </div>
              <!-- 描述 -->
              <div>
                <label for="weight-field" class="form-label">{{
                  $t("attribute.description")
                }}</label>
                <b-form-textarea
                  id="textarea"
                  v-model="form.description"
                  rows="3"
                  max-rows="6"
                  :disabled="isAbled"
                ></b-form-textarea>
              </div>
              <!-- 手机 -->
              <div>
                <label class="form-label">{{ $t("attribute.phone") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.phone"
                  :disabled="isAbled"
                />
              </div>
              <!-- 邮箱 -->
              <div>
                <label class="form-label">{{ $t("attribute.email") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.mail"
                  :disabled="isAbled"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="formType == 'edit'">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                class="btn btn-light me-4"
                @click="handleClose()"
                :disabled="formValidataAlert"
              >
                {{ $t("base.cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                id="add-btn"
                @click="handleSubmit()"
                :disabled="formValidataAlert"
              >
                {{ $t("base.done") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <ChangePassWord
      v-if="showEditPassWord"
      v-model:showEditPassWord="showEditPassWord"
    ></ChangePassWord>
    <message-alert
      :alertInfo="alertInfo"
      v-if="formValidataAlert"
      :showCloseBtn="true"
      v-model:formValidataAlert="formValidataAlert"
    ></message-alert>
  </Layout>
</template>

<script>
import { getUserInfo, postUserInfo } from "@/service/index";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import i18n from "@/i18n";
import _ from "loadsh";
import ChangePassWord from "./changePassWord.vue";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    form: {
      name: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_name"),
          required
        ),
      },
      username: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_user_name"),
          required
        ),
      },
      password: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_password"),
          required
        ),
      },
      role: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_role"),
          required
        ),
      },
    },
  },
  data() {
    return {
      PageHeaderT: this.$t("base.user_info"),
      PageHeaderItems: [],
      form: {
        name: "",
        description: "",
        disabled: false,
      },
      submitted: false,
      formValidataAlert: false,
      alertInfo: {
        variant: "success",
        content: "",
        icon: "ri-error-warning-line",
      },
      formType: "view",
      showEditPassWord: false,
    };
  },
  computed: {
    isAbled() {
      if (this.formType == "edit") {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.initUserInfo();
  },
  methods: {
    initUserInfo() {
      getUserInfo().then((res) => {
        console.log(res);
        if (res && res.payload) {
          this.form = res.payload;
        }
      });
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        let params = _.cloneDeep(this.form);
        params["confirm_password"] = this.form.password;
        params["disabled"] = !this.form.disabled;
        if (this.form.role) {
          params["role"] = [this.form.role];
        } else {
          params["role"] = [];
        }

        _.forEach(this.grantAll, function (value, key) {
          if (value.show) {
            params[key] = value.value;
          }
        });
        console.log(params);
        postUserInfo(params)
          .then((res) => {
            if (res) {
              let info = {
                variant: "success",
                content: this.$t("base.edit_success"),
                icon: "ri-alert-line",
              };
              this.openAlert(info, "close");
              this.$emit("initRoleList");
            }
          })
          .catch((err) => {
            let info = {
              variant: "danger",
              content: err.message,
              icon: "ri-error-warning-line",
            };
            this.openAlert(info, "close");
          });
      }
    },
    handleClose() {
      this.$router.back();
    },
    toggleStatus() {
      if (this.formType == "view") {
        this.formType = "edit";
      } else {
        this.formType = "view";
      }
    },
    /* 消息提示框 */
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
          this.handleClose();
        }, 2000);
      }
    },
  },
  components: {
    ChangePassWord,
  },
};
</script>

<style scoped lang='scss'>
.modal-content {
  width: 50%;
  max-height: 100%;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
