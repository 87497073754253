<template>
  <div class="modal fade show" style="display: block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form action="#" id="addform">
          <div class="modal-body">
            <div class="row gy-3">
              <!-- 原始密码 -->
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("base.original_password") }}</span>
                </label>
                <div class="position-relative auth-pass-inputgroup">
                  <input
                    :type="passwordType[0]"
                    autocomplete="old-passWord"
                    class="form-control"
                    style="padding-right: 3rem"
                    v-model="form.old_password"
                    :class="{
                      'is-invalid': submitted && v$.form.old_password.$error,
                    }"
                    required
                  />
                  <div
                    v-for="(item, index) in v$.form.old_password.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                  <button
                    class="
                      btn btn-link
                      position-absolute
                      top-0
                      text-decoration-none text-muted
                    "
                    style="right: 3%"
                    type="button"
                  >
                    <i
                      class="ri-eye-fill align-middle"
                      @click="viewPasswordClick(0)"
                    ></i>
                  </button>
                </div>
              </div>
              <!-- 新密码 -->
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("base.new_password") }}</span>
                </label>
                <div class="position-relative auth-pass-inputgroup">
                  <input
                    :type="passwordType[1]"
                    autocomplete="new-passWord"
                    class="form-control"
                    style="padding-right: 3rem"
                    v-model="form.password"
                    :class="{
                      'is-invalid': submitted && v$.form.password.$error,
                    }"
                    required
                  />
                  <div
                    v-for="(item, index) in v$.form.password.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                  <button
                    class="
                      btn btn-link
                      position-absolute
                      top-0
                      text-decoration-none text-muted
                    "
                    style="right: 3%"
                    type="button"
                  >
                    <i
                      class="ri-eye-fill align-middle"
                      @click="viewPasswordClick(1)"
                    ></i>
                  </button>
                </div>
              </div>
              <!-- 确认密码 -->
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span> {{ $t("base.confirm_password") }}</span>
                </label>
                <div class="position-relative auth-pass-inputgroup">
                  <input
                    :type="passwordType[2]"
                    autocomplete="confirm-passWord"
                    class="form-control"
                    style="padding-right: 3rem"
                    v-model="form.confirm_password"
                    :class="{
                      'is-invalid': submitted && confirmPw,
                    }"
                    :required="confirmPw"
                    @input="confirmChange(form.confirm_password)"
                  />
                  <button
                    class="
                      btn btn-link
                      position-absolute
                      top-0
                      text-decoration-none text-muted
                    "
                    style="right: 3%"
                    type="button"
                  >
                    <i
                      class="ri-eye-fill align-middle"
                      @click="viewPasswordClick(2)"
                    ></i>
                  </button>
                  <div class="invalid-feedback">
                    <span v-if="confirmPw">{{ confirmMsg }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                class="btn btn-light"
                @click="handleClose()"
                :disabled="formValidataAlert"
              >
                {{ $t("base.cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                id="add-btn"
                @click="handleSubmit()"
                :disabled="formValidataAlert"
              >
                {{ $t("base.done") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
    :showCloseBtn="true"
    v-model:formValidataAlert="formValidataAlert"
    class="alert-msg"
  ></message-alert>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { postPassWord } from "@/service/index";
import _ from "loadsh";
import i18n from "@/i18n";
export default {
  props: {
    showEditPassWord: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:showEditPassWord"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    form: {
      old_password: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_password"),
          required
        ),
      },
      password: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_password"),
          required
        ),
      },
    },
  },
  data() {
    return {
      form: {
        old_password: "",
        password: "",
        confirm_password: "",
      },
      submitted: false,
      formValidataAlert: false,
      confirmPw: false,
      confirmMsg: "",
      passwordType: ["password", "password", "password"],
      alertInfo: {
        variant: "success",
        content: "",
        icon: "ri-error-warning-line",
      },
    };
  },
  methods: {
    viewPasswordClick(index) {
      if (this.passwordType[index] == "password") {
        this.passwordType[index] = "text";
      } else {
        this.passwordType[index] = "password";
      }
    },
    handleClose() {
      this.$emit("update:showEditPassWord", false);
    },
    confirmChange(info) {
      if (info) {
        this.confirmPw = false;
      }
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        let params = _.cloneDeep(this.form);
        if (params["old_password"] == params["password"]) {
          let info = {
            variant: "danger",
            content: this.$t("base.filter_password"),
            icon: "ri-error-warning-line",
          };
          this.openAlert(info, "close");
          this.form.password = "";
          this.form.confirm_password = "";
          return;
        }
        if (params["confirm_password"] != params["password"]) {
          this.confirmPw = true;
          if (params["confirm_password"] == "") {
            this.confirmMsg = this.$t("user.hint_password");
          } else {
            this.confirmMsg = this.$t("base.confirm_password_info");
          }
          return;
        }
        postPassWord(params)
          .then((res) => {
            if (res) {
              if (res.code == 0) {
                this.alertInfo = {
                  variant: "success",
                  content: this.$t("base.change_success"),
                  icon: "ri-alert-line",
                };
                this.formValidataAlert = true;
                setTimeout(() => {
                  this.formValidataAlert = false;
                  this.$router.push("/login");
                  sessionStorage.removeItem("is_login");
                  sessionStorage.removeItem("set_menu_list");
                }, 2000);
              } else {
                let info = {
                  variant: "danger",
                  content: res.message,
                  icon: "ri-error-warning-line",
                };
                this.openAlert(info, "close");
              }
            }
          })
          .catch((err) => {
            let info = {
              variant: "danger",
              content: err.message,
              icon: "ri-error-warning-line",
            };
            this.openAlert(info, "close");
          });
      }
    },
    /* 消息提示框 */
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
        }, 2000);
      }
    },
  },
  components: {},
};
</script>

<style scoped lang='scss'>
.alert-msg {
  z-index: 99999;
}
</style>
